import React, {ReactElement, useCallback, useEffect} from 'react';
import './App.css';
import {AuthContext, AuthProvider} from "oidc-react";
import {
    Routes,
    Route,
    useNavigate, useLocation,
} from "react-router-dom";
import './App.css';
import {DatevFormatPage} from "./pages/DatevFormatPage/DatevFormatPage";
import { SignInPage } from './components/SignInComponent';
import Header from './components/Header';

const LAST_PATH_KEY = "lastPath"

const ProtectedRoute = ({children, isSignedIn}: React.PropsWithChildren<{ isSignedIn: boolean }>): ReactElement => {
    const auth = React.useContext(AuthContext);
    const handleLogout = useCallback(
      async () => {
        if (isSignedIn) {
          await auth?.signOut();
        }
      },
      [isSignedIn, auth]
    )

    const handleLogin = async () => {
        await auth?.signIn();
    };

    const body = isSignedIn ? children : <SignInPage login={handleLogin}/>;

    return (
      <>
        <div>
          <Header title="Cold Categorization" isSignedIn={isSignedIn} handleLogin={handleLogin} handleLogout={handleLogout}/>
          {body}
        </div>
      </>
    );
}


function AppWithAuth() {
    const location = useLocation()
    const auth = React.useContext(AuthContext);
    let lastUrlStorage = localStorage.getItem(LAST_PATH_KEY)
    if (lastUrlStorage === undefined || lastUrlStorage === null) {
        lastUrlStorage = "/"
    }
    console.group("Proces");
    console.log(process.env);
    console.groupEnd();

    useEffect(
        () => {
            if (location.pathname !== "/auth/callback" && location.pathname !== "/signin") {
                localStorage.setItem(LAST_PATH_KEY, location.pathname);
            }
        }
        , [location.pathname]
    )

    if (!auth || auth.isLoading) return <div> isLoading...</div>

    return (
        <div className="App">
            <Routes>
                <Route path="*" element={
                    <ProtectedRoute isSignedIn={auth.userData !== null}>
                        <DatevFormatPage/>
                    </ProtectedRoute>
                }/>
            </Routes>
        </div>
    );
}

function App() {

    const navigate = useNavigate();


    return (
        <AuthProvider
        autoSignIn={false}
        responseType="code"
        automaticSilentRenew={true}
        authority={process.env.REACT_APP_OIDC_AUTHORITY || "https://preprod-myaccount.agicap.cloud"}
        redirectUri={process.env.REACT_APP_OIDC_REDIRECT_URI || "http://localhost:3000/auth/callback"}
        popupRedirectUri={process.env.REACT_APP_OIDC_REDIRECT_URI || "http://localhost:3000/auth/callback"}
        silentRedirectUri={process.env.REACT_APP_OIDC_REDIRECT_URI || "http://localhost:3000/auth/callback"}
        clientId={process.env.REACT_APP_OIDC_CLIENT_ID || "agisight"}
        scope={process.env.REACT_APP_OIDC_SCOPE || "agicap:app openid profile"}
        onSignIn={(user) => {
            // @ts-ignore
            navigate("/")
        }}
    >
        <AppWithAuth/>
    </AuthProvider>
    )
}

export default App;
