import React, { CSSProperties, ReactNode } from 'react';

interface TypographyProps {
  component?: keyof JSX.IntrinsicElements;
  variant?: 'h1' | 'h6';
  color?: 'white';
  noWrap?: boolean;
  sx?: CSSProperties;
  children: ReactNode;
}

const Typography: React.FC<TypographyProps> = ({
  component,
  variant,
  color,
  noWrap,
  sx,
  children,
}) => {
  const styles: CSSProperties = {
    ...variant && { fontSize: variant === 'h1' ? '2rem' : '1.25rem', fontWeight: 'bold' },
    ...color && { color: '#ffffff' },
    ...noWrap && { whiteSpace: 'nowrap' },
    ...sx,
  };

  const Component = component || 'div';

  return <Component style={styles}>{children}</Component>;
};

export default Typography;