import React from 'react';

export const SignInPage: React.FC<{ login: () => Promise<void> }> = ({login}) => {

    return (
        <div>
            <h2>You are not logged in.</h2>
            <button onClick={login}> Sign In</button>
        </div>
    );
};
