import React from "react";
import {useConvertFile} from "../../hooks/convert";

const getFileFromEvent: (e: React.ChangeEvent<HTMLInputElement>) => File | undefined = (e) => {
    if (e.target.files) {
        return e.target.files[0];
    }
    return undefined;
}
export const DatevFormatPage: React.FC<{}> = () => {

    const {
        isSuccess,
        isLoading,
        isError,
        isValid,
        setClientNumber,
        setConsultantNumber,
        setFile,
        upload,
    } = useConvertFile();

    if (isSuccess) {
        return <div>Success</div>
    } else if (isError) {
        return <div>Error</div>
    } else if (isLoading) {
        return <div>Loading</div>
    }

    return (
        <form onSubmit={async (e) => {
            e.preventDefault();
            await upload();
        }}>
            <label>Mandatennummer</label>
            <input
                className="input"
                type="number"
                placeholder="Mandatennummer"
                onChange={(e) => setClientNumber(Number(e.target.value))}
            />
            <br/>
            <label>Beraternummer</label>
            <input
                className="input"
                type="number"
                placeholder="Beraternummer"
                onChange={(e) => setConsultantNumber(Number(e.target.value))}
            />
            <br/>
            <label>File to format</label>
            <input
                type="file"
                id="lfile"
                name="lfile"
                accept=".csv"
                onChange={(e) => setFile(getFileFromEvent(e))}
            />
            <br/>
            <button type="submit" disabled={!isValid}>Validate</button>
        </form>
    );
}
