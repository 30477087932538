import React, { CSSProperties, ReactNode } from 'react';

interface ToolbarProps {
  children: ReactNode;
  sx?: CSSProperties;
}

const Toolbar: React.FC<ToolbarProps> = ({ children, sx }) => {
  const defaultStyles: CSSProperties = {
    display: 'flex',
    justifyContent: 'justify-center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  };

  const mergedStyles = { ...defaultStyles, ...sx };

  return <div style={mergedStyles}>{children}</div>;
};

export default Toolbar;