import React from "react";

const TOKEN_SESSION_STORAGE_KEY = `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_OIDC_CLIENT_ID}`;
export const useConvertFile: () => {
    isSuccess: boolean,
    isLoading: boolean,
    isError: boolean,
    isValid: boolean,
    file?: File,
    setFile: (file?: File) => void,
    upload: () => Promise<void>,
    setClientNumber: (clientNumber: number) => void
    setConsultantNumber: (consultantNumber: number) => void
} = () => {
    const [file, setFile] = React.useState<File>();
    const [isError, setIsError] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
    const [clientNumber, setClientNumber] = React.useState<number | undefined>();
    const [consultantNumber, setConsultantNumber] = React.useState<number | undefined>();
    const isValid = file !== undefined && !!clientNumber && !!consultantNumber
    const convertToDatevFormat = async () => {
        if (isValid) {
            setIsLoading(true);
            try {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("client_number", clientNumber.toString());
                formData.append("consultant_number", consultantNumber.toString());
                const res = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/convert-file`,
                    {
                        method: "POST",
                        headers: {
                            // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryxyRHlne8kR7jovcW",
                            // @ts-ignore
                            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem(TOKEN_SESSION_STORAGE_KEY)).access_token}`,
                        },
                        body: formData,
                    }
                );
                if (res.status !== 200) {
                    throw Error(`API repsponded with a status code ${res.status}`)
                }
                const blob = await res.blob()
                setIsSuccess(true);
                let convertedFile = window.URL.createObjectURL(blob);
                const fileName = "datevasciiformat.zip"; // Replace with your desired file name and extension
                const link = document.createElement("a");
                link.href = convertedFile;
                link.download = fileName;
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);


            } catch (e) {
                console.error(e);
                setIsError(true);
            }
        }
    }

    return {
        isSuccess,
        isLoading,
        isError,
        isValid,
        file: file,
        clientNumber,
        consultantNumber,
        setClientNumber,
        setConsultantNumber,
        setFile,
        upload: convertToDatevFormat,
    }
}
