import Toolbar from './Toolbar';
import Typography from './Typography';

type HeaderProps = {
    title?: string
    isSignedIn: boolean
    handleLogin: () => Promise<void>
    handleLogout: () => Promise<void>
}

export default function Header({title, isSignedIn, handleLogin, handleLogout}: HeaderProps) {


  const loginButton = isSignedIn ? 
    <button onClick={handleLogout}> Logout </button> :
    <button color="inherit" onClick={handleLogin}> Sign In</button>;

  return (
    <Toolbar
      sx={{
          backgroundColor: '#316df0',
          marginBottom: 30,
      }}
    >

      <Typography
        component="h1"
        variant="h6"
        color="white"
        noWrap
        sx={{flexGrow: 1, marginLeft: 20}}
      >
          {title}
      </Typography>
      <div style={{ marginRight: 20}}>
        {loginButton}
      </div>
    </Toolbar>
  )
}
  